import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not Found" />
    <Navigation isAbsolute={false} currentPage="not_found" inverted />

    <div className="bg-vanilla-ice">
      <section className="container py-12 pt-8 lg:py-20 lg:pt-10 copied-text">
        <h1>Not Found</h1>
        <p>Diese Seite konnte nicht gefunden werden.</p>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
